<template>
  <div class="index">
    <img :src="$getImgUrl(`assets/4-2/1.png`)" alt="" />
    <img :src="$getImgUrl(`assets/4-2/2.png`)" alt="" />
    <img :src="$getImgUrl(`assets/4-2/3.png`)" alt="" />
    <img :src="$getImgUrl(`assets/4-2/4.png`)" alt="" />
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
  
<style lang="less" scoped>
.index {
  background: #fff;
}
</style>